import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const kontaktlencsevizsgalat = () => (
  <Layout>
    <SEO title="Kontaktlencse vizsgálat" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">




<div className="left2 mt-20 "><h3 className="maintext2 left2 ">A kontaktlencséket a szemüveg mellett, és nem helyette ajánljuk. Használata megkönnyíti mindennapjait (nem párásodik, sportolás...)</h3>
<h3 className="maintext2 mt-2 left2">
Az első kontaktlencse vizsgálatnál megtörténik a lencse helyes használatának betanítása.</h3>

<h3 className="maintext2 mt-2 left2">
Ez kb 2 órát szokott igénybe venni.</h3>













</div></div>

    </div></div>
  </Layout>
)

export default kontaktlencsevizsgalat
